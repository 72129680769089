<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>筛选搜索</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空
				</el-button>
				<el-button style="float: right" @click="searchList()" type="primary" size="small">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="listQuery" size="small" label-width="140px">
					<el-form-item label="昵称：">
						<el-input style="width: 203px" v-model="filter[0].val" placeholder="请输入用户名称/关键字"></el-input>
					</el-form-item>
					<el-form-item label="赛事：">
						<el-input style="width: 203px" v-model="filter[1].val" placeholder="请输入赛事名称"></el-input>
					</el-form-item>

				</el-form>
			</div>
		</el-card>
		<el-row>
			<!-- <el-button type="primary" size="medium" icon="el-icon-circle-plus-outline" @click="add()">新增</el-button> -->
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
					<el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left"
				circle @click="listQuery.page = 1;getList()"></el-button>
		</el-row>
		
		<div class="table-container">
			<el-table :data="list" stripe style="width: 100%" @selection-change="handleSelectionChange"
				v-loading="listLoading" border>
				<el-table-column type="selection" width="60" align="center"></el-table-column>

				<el-table-column v-for="item in checkArr" :key="item.val" v-if="item.dis" :prop="item.val" :label="item.label"
					min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'headImg'"><el-avatar size="medium" :src="scope.row.headImg"></el-avatar></span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="createdTxStamp" label="创建日期" min-width="150"></el-table-column> -->
				<el-table-column label="操作" min-width="200" fixed="right">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" icon="el-icon-tickets"
							@click="editTable(scope.$index, scope.row)">修改</el-button>
						<el-button type="primary" size="mini" icon="el-icon-tickets"
							@click="detail(scope.$index, scope.row)">详情</el-button>
					</template>
				</el-table-column>

			</el-table>
		</div>

		<div class="pagination-container">
			<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
				layout="total, sizes,prev, pager, next,jumper" :page-size="listQuery.pageSize" :page-sizes="[5, 10, 15]"
				:current-page.sync="listQuery.page" :total="total"></el-pagination>
		</div>

		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%">
			<el-form :model="permission" :rules="rules" ref="permissionFrom" label-width="150px">
				<el-form-item label="微信id：" prop="wxUserId">
					<el-input style="width: 250px" v-model="permission.wxUserId"></el-input>
				</el-form-item>
				<el-form-item label="赛事id：" prop="gameId">
					<el-input style="width: 250px" v-model="permission.gameId"></el-input>
				</el-form-item>
			</el-form>

			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" v-loading.fullscreen.lock="fullscreenLoadingA"
					@click="onSubmit('permissionFrom')">确 定</el-button>
			</span>
		</el-dialog>

		<el-dialog width="60%" title="详情" :visible.sync="dialogFormVisibles" :append-to-body="true">
			<el-table :data="tableDataS" :stripe="true" border v-loading="listLoadingS" style="width: 100%">
				<el-table-column prop="name" label="阶段名称"></el-table-column>
				<el-table-column prop="userType" label="攻/守方">
					<template slot-scope="scope">
						<span>{{scope.row.userType == 2 ? '守方' : scope.userType == 1 ?'攻方' : '无'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="integral" label="积分"></el-table-column>
				<el-table-column prop="trueNum" label="答对数"></el-table-column>
				<el-table-column prop="errorNum" label="答错数"></el-table-column>
				<el-table-column prop="isOut" label="是否被淘汰">
					<template slot-scope="scope">
						<span>{{scope.row.isOut == 0 ? '否' : '是'}}</span>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<!-- <el-pagination @current-change="handleCurrentChangeS" :current-page="pageS" layout="total, prev, pager, next, jumper" :total="totalS"></el-pagination> -->
		</el-dialog>
	</div>
</template>

<script>
	const defaultPermission = {
		id: '',
		wxUserId: '',
		gameId: '',
		honor: '',
		integral: 0,
	};

	const defaultFilter = [{
			col: 'niceName',
			type: 'like',
			val: ''
		},
		{
			col: 'gameName',
			type: 'like',
			val: ''
		}
	];

	export default {
		name: "userHistory",
		data() {
			return {
				listLoadingS: false,
				tableDataS: [],
				listQuery: {
					niceName: null,
					gameName: null,
					entity: 'FaExamUserGameHistory',
					page: 1,
					pageSize: 10
				},
				dialogTitle: '修改数据',
				dialogVisible: false,
				dialogFormVisibles: false,//详情弹窗
				visible: false,//表格右上角多选框
				listLoading:false,
				filter: defaultFilter,
				permission: defaultPermission,
				fullscreenLoadingA: false, //保存loading
				multipleSelection:[],
				list:[],
				page: 1,
				pageS: 1,
				total: 0,//分页总条数
				totalS: 0,
				// 表格选中
				handleSelectionChange(val) {
					this.multipleSelection = val;
				},
				rules: {
					wxUserId: [{
						required: true,
						message: '请输入wxUserId',
						trigger: 'blur'
					}],
					gameId: [{
						required: true,
						message: '请输入gameId',
						trigger: 'blur'
					}]
				},
				checkArr: [{
						label: "头像",
						val: "headImg",
						dis: true
					},
					{
						label: "玩家名称",
						val: "niceName",
						dis: true
					},
					{
						label: "分数",
						val: "integral",
						dis: true
					},
					{
						label: "赛事名称",
						val: "gameName",
						dis: true
					},
					{
						label: "获得头衔",
						val: "honor",
						dis: true
					},
					{
						label: "创建日期",
						val: "createdTxStamp",
						dis: true
					}
				],
				checkList: ["headImg", "niceName", "integral", "gameName", "honor", "createdTxStamp"],
				

			}
		},
		created() {
			this.getList();
		},
		methods: {
			handleSizeChange(val) {
				this.listQuery.pageNum = 1;
				this.listQuery.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.listQuery.pageNum = val;
				this.getList();
			},
			handleCurrentChangeS(val) {
				this.pageS = val;
				this.getListS();
			},
			searchList() {
				this.listQuery.pageNum = 1;
				this.getList();
			},
			getList() {
				
				this.listLoading = true;
				this.listQuery.filter = JSON.stringify(this.filter);
				this.$comjs.ajax.getAjax('/jqGrid/faCommonlist', this.listQuery, this, res => {
					
					this.list = res.rows;
				   // debugger;
					this.total = res.total;
					this.totalPage = res.total;
					this.listLoading = false;
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.listQuery.pageNum = 1;
				this.getList();
			}, // 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},

			onSubmit(fromName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.fullscreenLoadingA = true;
							this.$comjs.ajax.postAjax('/fa/exam/game/editUserGameHistory', this.permission,this, res => {
									this.fullscreenLoadingA = false;
									this.dialogVisible = false;
									this.$refs[formName].resetFields();
									this.$message({
										message: '保存成功！',
										type: 'success',
										duration: 1000
									});
									this.getList();
								});
						}).catch(() => {

						});
					}
				});
			},
			add() {
				this.dialogTitle = "新增数据";
				this.permission = Object.assign({}, defaultPermission);
				this.dialogVisible = true;
			},
			getListS(row) {
				this.listLoadingS = true;
				let data = {
					wxUserId: row.wxUserId,
					// pageNumber: this.pageS,
					// pageSize: 10
				}
				this.$comjs.ajax.getAjax('/fa/user/answer-detail', data, this, res => {
					this.tableDataS = res.list;
					// this.totalS = res.total;
					this.listLoadingS = false;
				});
			},
			// 修改
			editTable(index, row) {
				if (this.$refs.ruleForm) {
					this.$refs.ruleForm.clearValidate();
				}

				this.dialogTitle = "修改数据";
				this.permission.id = row.id;
				let query = {
					entity: this.listQuery.entity,
					id: this.permission.id
				}
				this.$comjs.ajax.getAjax('/jqGrid/commonFindDetailById', query, this, res => {
					this.permission.wxUserId = res.data.wxUserId;
					this.permission.gameId = res.data.gameId;
					this.permission.honor = res.data.honor;
					this.permission.integral = res.data.integral;
				});
				this.dialogVisible = true;

			},
			// 详情
			detail(index, row) {
				// this.pageS = 1;
				this.dialogFormVisibles = true;
				this.getListS(row);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.titles {
		border: 1px solid #E4E7ED;
		border-radius: 4px;
		line-height: 60px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
</style>
